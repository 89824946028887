@use "../../ui/vars" as *;

.recent-contributions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 52rem;
  padding: 0 1rem;
  width: 100%;

  @media screen and (min-width: $screen-xl) {
    padding: 0;
  }

  h2 {
    font: var(--type-heading-h4);
    margin-top: 1rem;
  }

  h5 {
    margin: 0;
  }

  .request-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.7rem;

    @media screen and (min-width: $screen-md) {
      flex-direction: revert;
    }

    &:nth-child(odd) {
      background: var(--background-secondary);
      border-radius: var(--elem-radius);
    }

    .request-date {
      align-items: center;
      display: flex;
      font-size: 12px;
      line-height: 175%;
    }
  }

  .request-title {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    line-height: var(--base-line-height);
    margin: 0;

    a {
      color: var(--text-primary);

      &:hover {
        color: var(--text-primary);
        text-decoration: underline;
      }
    }

    .request-repo {
      color: var(--text-secondary);
      font-size: var(--type-tiny-font-size);
    }
  }
}
