@use "../../vars" as *;

.article-actions {
  margin-left: auto;

  .article-actions-dialog-heading {
    display: none;
  }

  .button.action .button-wrap {
    text-transform: initial;
  }

  .article-actions-toggle {
    display: block;
  }

  @media screen and (min-width: $screen-md) {
    display: block;

    .article-actions-toggle {
      display: none;
    }
  }

  /* Open state, mobile-only */
  @media screen and (max-width: $screen-md) {
    &.show-actions {
      background-color: var(--background-primary);
      color: var(--text-primary);
      display: block;
      height: 100vh;
      left: 0;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      position: fixed;
      top: var(--top-navigation-height);
      width: 100vw;
      z-index: var(--z-index-low);

      > .button,
      .article-actions-entries > li > .button,
      .article-actions-entries > li > div > .button {
        --button-radius: 0;
        border-bottom: 1px solid var(--border-secondary);
        width: 100%;

        .button-wrap {
          justify-content: flex-start;
          margin-top: 1px;
          padding: 1.5rem 1rem;
        }
      }

      .article-actions-dialog-heading {
        display: block;
      }

      .article-actions-entries {
        display: block;
        padding: 1rem;
      }

      .article-actions-toggle,
      .article-action-entry {
        border-bottom: 1px solid var(--border-secondary);
      }

      .article-actions-entry {
        display: block;
      }
    }
  }
}

.article-actions-submenu {
  --gutter-padding: 1rem;
  display: none;
  overflow: auto;

  &.show {
    background: var(--background-primary);
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: var(--top-navigation-height);
    z-index: var(--z-index-mid);
  }

  &.wait {
    &,
    * {
      cursor: wait !important;
    }
  }

  .header {
    border-bottom: 1px solid var(--border-primary);
    color: var(--text-primary);
    display: block;
    font-family: var(--font-body);
    font-size: var(--type-smaller-font-size);
    font-weight: var(--font-body-strong-weight);
    margin: 0;
    padding: 1rem;
    text-align: left;
    width: 100%;

    .header-inner {
      align-items: center;
      display: flex;
      gap: 0.5rem;
      position: relative;
    }

    .icon {
      transform: rotate(90deg);
    }

    &.desktop-only {
      display: none;
    }
  }

  .mdn-form-item,
  p {
    margin: 0;
    padding: 1rem;
    padding-bottom: 0;

    &:last-child {
      padding-bottom: 1rem;
    }
  }

  .notecard {
    margin: var(--gutter-padding);
    margin-bottom: 0;

    &::before {
      top: 1.37rem;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (min-width: $screen-md) {
    &.show {
      background-color: var(--background-secondary);
      border: 1px solid var(--border-primary);
      border-radius: var(--elem-radius);
      bottom: auto;
      box-shadow: var(--shadow-02);
      left: var(--article-actions-position-left, initial);
      max-height: calc(100vh - 12px - var(--sticky-header-height));
      padding: 0;
      position: absolute;
      right: 0;
      top: calc(100% + 6px);
      width: 316px;
      z-index: var(--z-index-mid);
    }

    .header {
      display: block;
      padding: 1rem var(--gutter-padding);
      text-align: center;

      &-inner {
        justify-content: center;
      }

      &.desktop-only {
        display: block;
      }

      &.mobile-only {
        display: none;
      }

      .icon {
        left: 0;
        position: absolute;
      }
    }
  }
}

.article-actions-entries {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: $screen-md) {
    display: flex;
    gap: 0.5rem;

    .sidebar-toggle {
      display: none;
    }
  }
}

.article-actions-entry {
  align-items: center;
  display: flex;
  position: relative;
}
