$icons: "add-filled", "add", "altname", "bell-filled", "bell", "bookmark-filled",
  "bookmark", "cancel", "checkmark", "chevron", "chrome", "critical", "deno",
  "deprecated", "desktop", "disabled", "edge", "edit", "ellipses",
  "experimental", "external", "eye-filled", "eye", "feed", "filter", "footnote",
  "github-mark-small", "ie", "information", "language", "menu-filled", "menu",
  "mobile", "more", "theme-dark", "next", "no", "nodejs", "nonstandard",
  "note-info", "note-warning", "note-deprecated", "opera", "padlock", "partial",
  "prefix", "preview", "previous", "quote", "safari", "samsunginternet",
  "search", "send", "server", "sidebar", "simple-firefox", "small-arrow",
  "theme-light", "star-filled", "star", "theme-os-default", "thumbs-down",
  "thumbs-up", "trash", "twitter", "unknown", "warning", "webview", "yes",
  "yes-circle";

.icon {
  --size: var(--icon-size, 1rem);
  background-color: var(--icon-primary);
  display: inline-block;
  flex-shrink: 0;
  height: var(--size);
  mask-position: center;
  mask-repeat: no-repeat;
  vertical-align: middle;
  width: var(--size);

  @each $name in $icons {
    &.icon-#{$name} {
      mask-image: url("../../../assets/icons/" + $name + ".svg");
    }
  }
}

// The icon class is applied directly to SVGs sometimes. This
// override un-breaks those.
svg.icon {
  background: transparent;
  color: var(--icon-primary);
}
