@media screen and (min-width: $screen-md) {
  .table-container {
    width: calc(100% + 6rem);
  }

  .bc-table {
    tbody th {
      width: 20%;
    }
  }
}
