@use "../../vars" as *;

.document-survey {
  background-color: $mdn-color-light-theme-violet-10;
  border: 5px solid $mdn-color-light-theme-violet-70;
  border-radius: 0.5rem;
  color: $mdn-color-neutral-90;
  padding: 1rem;

  &::before {
    background: transparent url("../../../assets/icons/survey.svg") center
      center no-repeat;
    background-size: cover;
    content: "";
    display: inline-block;
    height: 33px;
    position: absolute;
    width: 32px;
  }

  iframe {
    border: 0;
  }

  button[type="button"],
  summary {
    cursor: pointer;
  }

  details {
    iframe {
      margin: 0.9rem;
      margin-bottom: 0;
      width: calc(100% - 1.2rem);
    }
  }

  summary {
    margin-top: 1rem;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: inherit;
    }
  }

  .survey-header {
    display: flex;
    justify-content: space-between;
  }

  .survey-header,
  .survey-container {
    padding-left: 2.5em;
  }

  .survey-dismiss svg.icon {
    color: $mdn-color-neutral-90;
  }
}
