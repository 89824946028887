@use "../../../ui/vars" as *;

.plus-subscribe-wrapper {
  background: var(--background-primary);

  .subscribe {
    margin: 0 auto;
    padding: 2rem 1rem;
    text-align: center;

    @media screen and (min-width: $screen-md) {
      padding: 2rem 4rem;
    }

    h2 {
      color: var(--text-primary);
      font-size: 24px;
      font-style: normal;
      font-weight: 650;
      line-height: 120%;
      margin-bottom: 2rem;
    }

    .switch {
      color: var(--text-primary);
      margin-bottom: 2rem;
    }

    .wrapper,
    .wrapper-offline {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      margin: 0 auto;

      .wrapper-offline {
        display: none;
      }

      @media screen and (min-width: 66rem) {
        align-items: stretch;
        flex-direction: row;
      }

      .subscribe-detail {
        align-items: center;
        background-color: var(--text-secondary);
        border-radius: 1rem;
        color: var(--text-invert);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 20rem;

        min-width: 16rem;
        width: 100%;

        &#plus5 {
          background-color: var(--text-primary-blue);
        }

        &#plus10 {
          background-color: var(--text-primary-red);
        }

        &#core {
          background-color: var(--text-primary-green);
        }

        h3 {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 1rem 1rem 0 0;
          color: var(--text-primary);
          font-size: 1em;
          margin: 0;
          padding: 0.5rem;
          width: 100%;
        }

        .sub-info {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          height: 100%;
          margin-top: 1rem;
          padding: 0 2rem 2rem;
          width: 100%;

          h3,
          p {
            margin: 0;
          }

          .price {
            align-content: center;
            column-gap: 0.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 3rem;

            .sub-price {
              font-size: 36px;
              font-style: normal;
              font-weight: 650;
              grid-column: 1/2;
              justify-self: right;
              line-height: 120%;
            }

            .free {
              grid-column: 1/3;
              justify-self: center;
            }

            .sub-length {
              font-size: 0.8rem;
              font-style: normal;
              grid-column: 2/3;
              justify-self: left;
              line-height: 120%;
              margin: auto 0;
              text-align: left;
            }
          }

          .includes {
            align-self: flex-start;
          }

          ul {
            align-self: flex-start;
            list-style-type: "✔ ";
            margin-left: 1rem;
            width: 100%;

            li {
              line-height: 1.75;
              padding-left: 0.5rem;
              text-align: left;
              width: 100%;

              a {
                color: var(--text-invert);
                text-decoration: underline;
                width: fit-content;

                &:hover {
                  text-decoration: none;
                }
              }

              sup.new {
                --new-background: var(--mdn-color-background-highlight);
                --new-color: inherit;
                margin-left: 0.25rem;
              }
            }
          }

          .sub-link {
            align-items: center;
            align-self: center;
            background: $mdn-color-neutral-90;
            border-radius: 1rem;
            color: var(--text-primary);
            display: flex;
            font-weight: 620;
            height: 2rem;
            justify-content: center;
            min-width: fit-content;
            padding: 0.25rem 0.5rem;
            width: calc(100% - 1rem);
          }

          .terms {
            color: var(--text-invert);
            font-size: 14px;
            font-style: italic;
            margin-top: auto;
            text-decoration: underline;

            &.external::after {
              background-color: var(--text-invert);
            }
          }

          .current {
            background-color: initial;
            border: 1px solid var(--mdn-color-black);
            color: var(--mdn-color-black);
          }

          .na {
            background-color: var(--mdn-background-light-grey);
            border: 1px solid var(--mdn-background-light-grey);
            color: var(--mdn-color-dark-grey);

            &::after {
              background-color: var(--mdn-color-dark-grey);
              content: "";
              display: inline-block;
              height: 16px;
              margin-left: 0.3rem;
              mask-image: url("../../../assets/icons/question-mark.svg");
              width: 16px;
            }
          }
        }
      }
    }
  }
}

.plus-for-companies {
  color: var(--text-primary);
  margin: 0;
  padding: 1rem;
  padding-bottom: 2rem;
  text-align: center;
}
