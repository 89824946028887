@use "../../ui/vars" as *;
@use "../../ui/base/mdn" as *;

main.advertise-with-us {
  margin-bottom: 3rem;
  width: 100%;

  .stats-container {
    background-color: var(--background-primary);
    color: var(--text-primary);
    margin-bottom: 3rem;
    width: 100%;
  }

  section {
    margin: 0 auto;
    max-width: 52rem;
    padding: 0 1rem;

    &.stats-header {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem 2rem;
    }

    h1 {
      @include mify;
      font-size: 3rem;
      margin-top: 8rem;
      text-align: center;

      &::before {
        background-color: var(--mdn-color-ads);
      }

      &::after {
        text-decoration-color: var(--mdn-color-ads);
      }
    }

    .quote {
      &.owd {
        background-color: var(--mdn-color-ads);
        color: var(--background-primary);

        .icon {
          background-color: var(--background-primary);
        }
      }

      &.pab {
        background-color: var(--background-primary);
        color: var(--text-primary);

        .icon {
          background-color: var(--text-primary);
        }
      }
    }

    .stats {
      display: grid;
      gap: 0.3em;

      @media screen and (max-width: $screen-md) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: $screen-sm) {
        grid-template-columns: 1fr;
      }

      @media screen and (min-width: $screen-md) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      li {
        align-items: center;
        background: var(--mdn-color-ads);
        color: var(--background-primary);
        display: flex;
        flex-direction: column;
        padding: 0.5rem 2rem;

        .number {
          font-size: 3rem;

          svg {
            transform: translateY(0.125em);
          }
        }

        .legend {
          font-size: 0.8rem;
          max-width: 5rem;
          text-align: center;
        }
      }
    }
  }
}
