// Style for mobile.

@media screen and (max-width: $screen-sm - 1px) {
  .bc-table {
    thead {
      display: none;
    }

    td.bc-support {
      border-left-width: 0;
      display: block;
    }

    .bc-feature,
    .bc-support > button,
    .bc-history > td {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .bc-history-desktop {
      display: none;
    }
  }

  .table-container {
    overflow-x: auto;
  }
}
