@use "../../../ui/vars" as *;

.offer-overview-feature {
  background-color: var(--background-primary);
  color: var(--text-primary);
  min-height: 25rem;
  width: 100%;

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 64rem;
    padding: 4rem 1rem;
    width: 100%;

    @media screen and (min-width: $screen-xl) {
      flex-direction: row;
    }

    .copy-container,
    .img-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .img-container {
      align-items: center;
      border-radius: 1rem;
      box-shadow: 0 3px 22px #2b2a331a;
      height: 100%;
      max-width: 25rem;
      @media screen and (min-width: $screen-xl) {
        max-width: min(50%, 20rem);
      }
    }

    .copy-container {
      align-items: center;
      max-width: 40rem;

      text-align: center;

      a {
        justify-content: start;
      }

      h2 {
        color: var(--plus-accent-color);
        font: 700 12px/120% Inter;
        letter-spacing: 1.5px;
        margin-bottom: 1rem;
        margin-top: 0;
        text-transform: uppercase;
      }

      h3 {
        font-size: 1.75rem;
        font-weight: 400;
        margin-top: 0;
      }

      @media screen and (min-width: $screen-xl) {
        align-items: initial;
        height: 100%;
        max-width: 50%;
        text-align: initial;
      }
    }
  }
}

.offer-overview-feature:nth-child(even) {
  .wrapper {
    flex-direction: column;
    @media screen and (min-width: $screen-xl) {
      flex-direction: row;
    }
  }
}

.offer-overview-feature:nth-child(odd) {
  background: var(--background-secondary);

  .wrapper {
    flex-direction: column;
    @media screen and (min-width: $screen-xl) {
      flex-direction: row-reverse;
    }
  }
}
