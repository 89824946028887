.mdn-form {
  font-family: var(--font-body);
  font-size: var(--type-smaller-font-size);
  font-weight: var(--font-body-strong-weight);
}

.mdn-form-item {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-top: 1rem;

  &:first-child {
    padding-top: 0;
  }

  &.is-button-row {
    display: flex;
    justify-content: flex-end;

    .button {
      width: auto;
    }
  }

  [type="text"],
  [type="email"],
  textarea,
  select {
    background: var(--background-primary);
    border: 1px solid var(--border-primary);
    color: var(--text-primary);
    flex: 1 1 100%;
    font-size: var(--type-smaller-font-size);
    min-width: 0;
    padding: 0.5rem;

    &:focus {
      border: 1px solid var(--button-secondary-border-focus);
      box-shadow: var(--focus-effect);
      outline: 0 none;
    }
  }

  select {
    appearance: none;
  }

  .select-wrap {
    display: flex;
    flex: 1 1 100%;
    position: relative;
  }

  .select-wrap::after {
    background-color: var(--icon-primary);
    content: "";
    display: block;
    height: 16px;
    mask-image: url("../../../assets/icons/small-arrow.svg");
    mask-size: cover;
    pointer-events: none;
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 0.5rem);
    width: 16px;
  }

  select,
  label {
    text-overflow: ellipsis;
    width: 100%;
  }

  :invalid {
    border-color: var(--form-invalid-color);

    &:focus {
      border-color: var(--form-invalid-focus-color);
      box-shadow: 0 0 0 3px var(--form-invalid-focus-effect-color);
    }
  }

  .limit {
    color: var(--form-limit-color);
    font-size: 0.6875rem;
    margin-left: auto;

    b {
      color: var(--form-limit-color-emphasis);
    }

    &.invalid b {
      color: var(--form-invalid-color);
    }
  }
}

.modal-body,
.mdn-form.mdn-form-big {
  .mdn-form-item {
    padding-top: 1.5rem;

    &:first-child {
      padding-top: 0;
    }

    label,
    textarea,
    [type="text"],
    [type="email"] {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  .button-wrap {
    font-size: 1rem;
    height: auto;
    line-height: 1.5;
    padding: 0.5rem 2rem;
  }
}
