@use "../../vars" as *;

/*
Keyboard & screen reader skip link menu
********************************************************************** */

.a11y-nav {
  position: absolute;
  top: -20em;
  width: 100%;
  z-index: var(--z-index-a11y);

  a {
    background-color: rgba(255, 255, 255, 0.9);
    font-weight: var(--font-body-strong-weight);
    left: 0;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    text-align: center;

    &:hover,
    &:focus {
      box-shadow: var(--shadow-01);
      text-decoration: none;
      top: 20em;
    }
  }
}
