@use "../ui/vars" as *;

.query-string {
  font-style: italic;
}

.site-search {
  display: block;

  article {
    @media screen and (max-width: $screen-md) {
      display: flex;
      flex-direction: column;
    }

    .place {
      float: right;
      padding: 1rem;
      @media screen and (max-width: $screen-md) {
        align-self: center;
        float: none;
        order: 4;
      }
    }
  }
}
