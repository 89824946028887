@use "../ui/vars" as *;

article.settings {
  display: block;
  margin: auto;
  max-width: 60em;
  padding: 1rem;
  width: min(90vw, 40em);

  h3 {
    margin-top: 0.5rem;
  }

  .field-group {
    margin: 2rem 0;

    ul {
      padding: 0;

      li {
        display: grid;
        gap: 0 1rem;
        grid-template-columns: 1fr minmax(5em);
        margin: 2em 0;

        h3 {
          font-weight: 600;
        }

        p {
          margin: 0;
        }

        span {
          font-size: 0.8rem;
          grid-column: 1/2;
          line-height: 1.2em;
          padding-top: 0.3em;
        }

        .loading,
        .switch {
          align-self: center;
          grid-column: 2/3;
          grid-row: 1/3;
          justify-self: end;
        }

        button,
        .manage {
          align-self: center;
          border: 1px solid var(--text-primary);
          border-radius: var(--elem-radius);
          color: var(--text-link);
          font-size: 0.8rem;
          grid-column: 2/3;
          grid-row: 1/3;
          height: max-content;
          justify-self: end;
          min-width: 10rem;
          padding: 0.5rem 1rem;
          text-align: center;
          width: max-content;
        }

        button[disabled] {
          background-color: var(--background-secondary);
          border: 1px solid var(--text-secondary);
          color: var(--text-secondary);
        }
      }
    }
  }
}
