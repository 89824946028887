@use "../../vars" as *;

body.ReactModal__Body--open {
  overflow: hidden;
}

.modal-overlay {
  background: var(--background-primary);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-modal-overlay);

  &.wait {
    &,
    * {
      cursor: wait !important;
    }
  }
}

.modal-content {
  background: var(--background-primary);
  flex-basis: 32.5rem;
  outline: none;
  padding: 1.5rem;
  z-index: var(--z-index-modal-content);
}

.modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;

  .button.has-icon {
    .button-wrap {
      height: auto;
      margin: -0.5rem;
      padding: 0.5rem;
      width: auto;
    }
  }

  .icon {
    background-color: #000;
    height: 1.25rem;
    margin: 0;
    width: 1.25rem;
  }
}

.modal-heading {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.modal-body {
  .notecard {
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
    margin-top: 1rem;
    overflow-wrap: anywhere;

    &:first-child {
      margin: 0;
    }
  }

  .mdn-form-item {
    @media screen and (max-width: $screen-lg) {
      &:last-child {
        padding-bottom: 1.5rem;
      }
    }

    &.is-button-row {
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;
      gap: 1.5rem;
      justify-content: flex-start;
    }
  }

  button {
    flex: 1;
  }
}

@media screen and (min-width: $screen-lg) {
  body.ReactModal__Body--open {
    overflow: unset;
  }

  .modal-overlay {
    align-items: center;
    background-color: rgba(240, 240, 244, 0.66);
  }

  .modal-content {
    border-radius: var(--elem-radius);
    box-shadow: 0 2px 14px rgba(58, 57, 68, 0.2);
    height: fit-content;
    max-height: 90vh;
    max-width: 40rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 90vw;

    &.is-small {
      max-width: 24rem;
    }

    button {
      flex: unset;
    }
  }
}
