@use "../ui/vars" as *;

.blog-container.post {
  max-width: calc(48rem + 4rem);

  + .section-newsletter h2 {
    font: var(--type-heading-h3);
    margin: 0;
  }
}

.blog-container {
  --author-gap: 1rem;
  --avatar-size: 3rem;

  .date-author,
  .author {
    align-content: flex-start;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .date-author {
    column-gap: 1.5rem;
    padding-left: calc(var(--avatar-size) + var(--author-gap));
  }

  .author {
    font-weight: var(--font-body-strong-weight);
    gap: var(--author-gap);
    margin-left: calc((var(--avatar-size) + var(--author-gap)) * -1);

    &::after {
      margin-left: calc(4px - var(--author-gap));
    }

    img {
      border: none !important;
      border-radius: 3rem;
      height: var(--avatar-size);
      margin: 0;
      object-fit: cover;
      width: var(--avatar-size);
    }
  }

  figure.blog-image {
    margin: 0 auto 2rem;
    width: fit-content;

    img {
      background: transparent;
      border: none !important;
      margin: 0 0 0.125rem;
      width: 100%;
    }

    figcaption {
      font-size: smaller;
      margin-left: auto;
      width: fit-content;
    }
  }

  .previous-next {
    display: flex;
    gap: 1rem;

    @media screen and (max-width: $screen-md) {
      flex-direction: column-reverse;
    }

    a {
      color: var(--text-primary);
      display: flex;
      gap: 1rem;
      text-decoration: none;
      width: 100%;

      &:hover h2 {
        text-decoration: underline;
      }

      &:active {
        background: none;
      }

      @media screen and (min-width: $screen-md) {
        &.previous,
        &.next {
          &::before,
          &::after {
            align-self: center;
            background-color: var(--text-primary);
            flex-shrink: 0;
            height: 1rem;
            mask-position: center;
            mask-repeat: no-repeat;
            vertical-align: middle;
            width: 1rem;
          }
        }

        &.previous::before {
          content: "";
          mask-image: url("../assets/icons/previous.svg");
        }

        &.next::after {
          content: "";
          mask-image: url("../assets/icons/next.svg");
        }
      }
    }

    article {
      margin: 0 auto;
    }

    h2:first-of-type {
      color: var(--text-link);
      font-size: 1rem;
      margin: 0;
      text-align: center;

      strong {
        color: var(--text-primary);
        display: block;
        font-size: 0.8em;
        font-weight: normal;
        line-height: 1.2rem;
      }
    }
  }
}
