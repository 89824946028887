@use "../ui/vars" as *;

.blog {
  --category-color: var(--apis-accent-color);

  .top-navigation {
    --text-link: var(--category-color);
  }
}

.blog-container {
  margin: 0 auto;
  max-width: min(calc(80vw + 4rem), var(--max-width));
  padding: 2rem 1rem;

  .sponsored {
    color: var(--icon-primary);

    &::before {
      background-color: var(--icon-primary);
      content: "";
      display: inline-block;
      height: 1em;
      margin-bottom: -0.15em;
      margin-right: 0.3em;
      mask-image: url("../assets/icons/note-info.svg");
      mask-position: center;
      mask-repeat: no-repeat;
      width: 1em;
    }
  }

  h1 {
    margin-top: 1rem;
  }

  &.blog-index {
    > header {
      width: 100%;

      > h1 {
        margin: 0 auto 2rem;
        width: fit-content;

        &::before {
          background-color: var(--category-color);
        }

        &::after {
          text-decoration-color: var(--category-color);
        }
      }
    }

    .article-list {
      column-gap: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: $screen-lg) {
        grid-template-columns: auto;
      }
    }

    article {
      border: 1px solid var(--border-primary);
      border-radius: 1rem;
      display: grid;
      grid-row: span 6;
      grid-template-rows: subgrid;
      margin-top: 2rem;
      padding: 2rem;

      a.button {
        margin-left: auto;
        text-decoration: var(--button-bg);
      }

      header {
        display: grid;
        flex-direction: column;
        grid-row: span 3;
        grid-template-rows: subgrid;

        figure.blog-image {
          margin-bottom: 0;

          img {
            margin-bottom: 0;
            max-height: 200px;
            width: auto;
          }
        }

        h2:first-of-type {
          align-self: center;
          font: var(--type-heading-h3);
          font-size: 1.75rem;
          font-weight: 400;
          margin-bottom: 2.25rem;
          margin-top: 1.5rem;
        }
      }

      p {
        margin-bottom: 2.25rem;
        margin-top: 1.5rem;
      }

      footer {
        align-items: center;
        align-self: end;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }
}
